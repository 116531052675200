@import "tailwindcss" source("../../");

@plugin "@tailwindcss/forms";
@plugin "@tailwindcss/typography";
/* enable this when supported */
/* @plugin "@headlessui/tailwindcss"; */

@theme {
  --min-height-180: 180px;

  --max-width-: 0.25rem;
  --max-width-2: 0.5rem;
  --max-width-3: 0.75rem;
  --max-width-4: 1rem;
  --max-width-5: 1.25rem;
  --max-width-6: 1.5rem;
  --max-width-7: 1.75rem;
  --max-width-8: 2rem;
  --max-width-9: 2.25rem;
  --max-width-10: 2.5rem;
  --max-width-11: 2.75rem;
  --max-width-12: 3rem;
  --max-width-13: 3.25rem;
  --max-width-14: 3.5rem;
  --max-width-15: 3.75rem;
  --max-width-16: 4rem;
  --max-width-17: 4.25rem;
  --max-width-18: 4.5rem;
  --max-width-19: 4.75rem;
  --max-width-20: 5rem;
  --max-width-21: 5.25rem;
  --max-width-22: 5.5rem;
  --max-width-23: 5.75rem;
  --max-width-24: 6rem;
  --max-width-25: 6.25rem;
  --max-width-26: 6.5rem;
  --max-width-27: 6.75rem;
  --max-width-28: 7rem;
  --max-width-29: 7.25rem;
  --max-width-30: 7.5rem;
  --max-width-31: 7.75rem;
  --max-width-32: 8rem;

  --color-primary: #822c42;
  --color-primary-dark: #692435;
  --color-primary-light: #eac2cc;
  --color-gray-primary: #97999b;
  --color-primary-brown: #c2b8b2;
  --color-primary-brown-dark: #655953;
  --color-primary-brown-light: #e4e0dd;
  --color-primary-green: #378195;
  --color-primary-green-dark: #275968;
  --color-primary-green-light: #b5d9e3;
  --color-primary-yellow: #f4ce67;
  --color-primary-yellow-dark: #866409;
  --color-primary-yellow-light: #faedc7;
  --color-primary-blue: #49588f;
  --color-primary-blue-dark: #37436c;
  --color-primary-blue-light: #c9cfe3;

  --grid-template-columns-16: repeat(16, minmax(0, 1fr));
  --grid-template-columns-28: repeat(28, minmax(0, 1fr));
}

@utility hyphens {
  hyphens: auto;
}

@utility no-hyphens {
  hyphens: none;
}

@utility activity-change-unit-offer {
  background-color: var(--color-primary);
  @apply text-white!;

  & * {
    @apply text-white!;
  }
}

@utility activity-change-unit-sale {
  background-color: var(--color-primary-green);
  @apply text-white!;

  & * {
    @apply text-white!;
  }
}

button {
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.leaflet-container {
  @apply absolute;
  @apply h-full;
  @apply w-full;
  @apply object-cover;
}

.react-select input,
.react-select input:focus {
  @apply shadow-none ring-0 border-0 outline-hidden;
}

.react-select > div > div {
  @apply justify-start;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  @apply mt-2;
}

.react-datepicker select {
  @apply p-1 pr-8 text-xs;
}

.react-autosuggest__container input {
  @apply block w-full border rounded-xs shadow-xs h-10 px-3 focus:outline-hidden sm:text-sm placeholder-gray-300 border-gray-300 focus:ring-gray-700 focus:border-gray-700;
}
/* 
.react-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__month-text--keyboard-selected,
.react-datepicker .react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: transparent;
  color: #000;
} */
/* 
.react-datepicker .react-datepicker__day--keyboard-selected:hover,
.react-datepicker .react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker .react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker .react-datepicker__year-text--keyboard-selected:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
} */

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/**
input {
  transition: 200ms;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
    no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}
*/
